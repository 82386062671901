.embeddedobject--columns
{
  display: -ms-webkit-flexbox;
  display: -webkit-flex;
  display: flex;
}

.columns__col1
{
  width: 0;
  flex-basis: 50%;
  padding-right: 10px;
}
.columns__col2
{
  padding-left: 10px;
  width: 0;
  flex-basis: 50%;
}
