@font-face {
    font-family: 'raleway';
    src: url('raleway-regular-webfont.woff2') format('woff2'),
         url('raleway-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'raleway';
    src: url('raleway-bold-webfont.woff2') format('woff2'),
         url('raleway-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'raleway';
    src: url('raleway-semibold-webfont.woff2') format('woff2'),
         url('raleway-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

html
{
  font: 14px/1.5em 'Raleway', sans-serif;
  color: #8d8d8d;
  letter-spacing: 1px;
  background: #ffffff;
}

main a[href]
{
  color: #4a596c;
  text-decoration: none;
}
main a[href]:hover
{
  text-decoration: underline;
}

h1
{
  font: bold 17px/30px 'Raleway', sans-serif;
  color: var(--headings, #3e3e3e);
  margin: 0;
  letter-spacing: 3px;
  text-align: center;
  border: 1px solid #d7d7d7;
  padding: 20px;
}
h2
{
  color: var(--headings, #4a596c);
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0 0 0;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}
h3
{
  color: var(--headings, #4a596c);
  font-size: 110%;
  margin: 16px 0 0 0;
}
p.centered
{
  text-align: center;
  color: var(--headings, #8d8d8d);
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 1px;
}

table.litters {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  border-collapse:collapse;
  text-align: center;
}
table.litters th {
  font-weight: bold;
  font-style: italic;
  background-color:#5ab3b1;
  color:#ffffff;
}
table.litters td, table.litters th {
  border:1px solid #147774;
}
table.litters td {
  background-color: rgba(90, 179, 177, .7);
  color:#393939;
  padding: 5px;
}

.wh-rtd__tablecell
{
  padding:0 1px;
}

.wh-rtd__html.bodycolumns2 .wh-rtd__body
{
  width: 500px;
}
