.embeddedobject--slideshow
{
  position: relative;

  padding-top: 75%;
}

.embeddedobject--slideshow .carrousel__viewport
{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  height: auto !important; /* sorry */
}
.embeddedobject--slideshow .carrousel__cell
{
  width: 100%;
  height: 100%;
}

/* important carrousel__viewport behaviour */
.embeddedobject--slideshow .carrousel__viewport
{
  overflow: hidden;

  -webkit-tap-highlight-color: transparent; /* iOS Safari */

  cursor: -webkit-grab; /* Safari < 9 */
  cursor: grab;

  /* prevent selection.
     We cannot use preventDefault because touch devices must be able to
     pick up the touch events to initiate a vertical scroll.
  */
  -ms-user-select: none; /* IE10/11 & Edge */
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/*
.embeddedobject__slideshow.notinitialized .carrousel__cell
{
  visibility: hidden;
}
.embeddedobject__slideshow.notinitialized .carrousel__cell:first-child
{
  visibility: visible;
}
*/

.embeddedobject--slideshow .carrousel__cell
{
  position: absolute;
  top: 0;
  width: 100%;
}




.carrousel__cell
{
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.carrousel__previous
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.carrousel__next
{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}


  .carrousel__previous
, .carrousel__next
{
  -ms-display: flexbox;
  -webkit-display: flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.25);
  opacity: 0;

  padding: 0 5px;

  transition: opacity 0.25s;
  cursor: pointer;
}

  .carrousel__previous:hover
, .carrousel__next:hover
{
  opacity: 1;
}


  .carrousel__previous svg
, .carrousel__next svg
{
  width: 25px;
  height: 25px;
  fill: #FFFFFF;
}



.embeddedobject--slideshow .carrousel__viewport:focus
{
  outline: 0;
}

.carrousel__jumpbuttons
{
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  pointer-events: none;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.carrousel__jumpbutton
{
  padding: 5px; /* enlarge clickable area */
  /*background-color: rgba(255,0,0,0.25);*/
}
.carrousel__jumpbutton::before
{
  display: block;
  content: "";

  width: 12px;
  height: 12px;
  background-color: rgba(255,255,255,0.2);
  transition: background-color 0.25s;

  border: 1px solid rgba(0,0,0,0.75);
  border-radius: 50%;

  pointer-events: auto;
  cursor: pointer;
}
/*.carrousel__jumpbutton:active::before*/
.carrousel__jumpbutton.active::before
{
  background-color: rgba(255,255,255,0.75);
}
.carrousel__jumpbutton + .carrousel__jumpbutton
{
  /*margin-left: 5px;*/
}

.columns__col1 .carrousel__jumpbuttons
{
  justify-content: flex-start;
}
.columns__col2 .carrousel__jumpbuttons
{
  justify-content: flex-end;
}
