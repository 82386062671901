@import './richcontent.css';
.slideshow {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slideshow:after {
  display: block;
  content: "";
  padding-bottom: 27%;
}
.slideshow__slide {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 100%;
}
.slideshow .slideshow__slide {
  opacity: 1;
  transition-property: opacity;
}
.slideshow .slideshow__slide.slideshow--slidein {
  opacity: 0;
}
.slideshow .slideshow__slide.slideshow--slideout {
  opacity: 0;
}
.slideshow .slideshow__slide img {
  max-width: 100%;
  height: auto;
}

:root {
  --background: #ffffff;
  --background-footer: #f6f6f6;
  --color1: #393939;
  --color2: #ffffff;
  --color3: #c9c9c9;
  --color4: #c9c9c9;
}

html {
  background-color: var(--background);
}

html.dragon {
  --background: #0b2d39;
  --color1: #ffffff;
  --color2: #0b2d39;
  --color3: #c9c9c9;
  --color4: #000000;
  --background-footer: #0b2d39;
  --headings: #ffffff;
  color: #ffffff;
}
html.dragon h1 {
  color: #ffffff;
}

* {
  box-sizing: border-box;
}

.header {
  height: 130px;
  position: relative;
  z-index: 1;
}

.header__menuholder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 0 0 0;
  height: 130px;
  background: var(--background);
  text-align: center;
}

.header__topmenulogo, .header__topmenuitem {
  vertical-align: middle;
  display: inline-block;
}

.header__topmenuitem {
  margin: 0 10px;
}

.pure-menu-link:visited {
  color: var(--color2);
}

.pure-menu-link {
  font: 600 13px "Raleway", sans-serif;
  letter-spacing: 3px;
  display: inline-block;
}

.pure-menu-children {
  padding-top: 30px;
  background-color: transparent;
}

.pure-menu-children li {
  background-color: var(--color1);
  text-align: left;
  padding: 5px 65px 5px 2px;
}

.pure-menu-children li a {
  font-family: "Raleway";
  font-size: 12px;
  letter-spacing: 1px;
}

.header__firstlevellink,
.header__firstlevellink:visited {
  color: var(--color1) !important;
  text-transform: uppercase;
  padding: 0 15px;
  width: 100%;
}

.header__firstlevellink:hover {
  background-color: inherit;
  color: var(--color3) !important;
}

.header__secondlevellink,
.header__secondlevellink:visited {
  width: 100%;
  color: var(--color2) !important;
}

.header__secondlevellink:hover {
  background-color: inherit;
  color: var(--color4) !important;
}

.main {
  max-width: 960px;
  margin: 30px auto 30px;
}

.credits {
  background: var(--background-footer);
  padding: 16px 30px;
  font: 400 13px/1.8em "Raleway", sans-serif;
  text-align: center;
}